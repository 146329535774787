
import './i18n';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import { REVISION } from './config';
import { WindowProvider } from './contexts/WindowContext';
import { Intercom } from './intercom';
import Routes from './Routes';

const container = document.getElementById('root');
const root = createRoot(container);

console.log('Build', REVISION);

root.render(<React.StrictMode>
    <WindowProvider>
        <Router>
            <Routes />
        </Router>
    </WindowProvider>
</React.StrictMode>);

Intercom.init();
