import Action from '../Action';
import AppleEn from './assets/apple_en.png?as=jpg&width=200';
import AppleFr from './assets/apple_fr.png?as=jpg&width=200';
import GoogleEn from './assets/google_en.png?as=jpg&width=200';
import GoogleFr from './assets/google_fr.png?as=jpg&width=200';

import * as style from './style.module.css';

const BADGES = {
    apple: {
        en: AppleEn,
        fr: AppleFr,
    },
    google: {
        en: GoogleEn,
        fr: GoogleFr,
    },
}

const StoreBadge = ({ action = null, type = '', language = '' }) => {
    const backgroundImage = BADGES?.[type.toLowerCase()]?.[language.toLowerCase()]
    return <Action action={action} className={style.badge} style={{ backgroundImage: backgroundImage ? `url("${backgroundImage}")` : '' }} />;
};

export default StoreBadge;
