.badge {
    width: 210px;
    min-width: 210px;
    height: 60px;
    background-size: cover;
    background-position: center center;
    border-radius: 8px;
    display:block;
    transition: transform .3 ease-in-out;
}

.badge:hover {
    filter: brightness(1.25)
}