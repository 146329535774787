.footer {
    background: #222831;
}

.footer>div {
    align-content: center;
    align-items: center;
    color: var(--color-white);
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: auto;
    margin-top: var(--space-xxl);
    max-width: 1100px;
    padding: var(--space-xxl) 0;
    text-decoration: none;
    width: calc(100% - (2* var(--space-m)));
}

.footer a svg {
    color: var(--color-white);
    height: 30px;
    transition: opacity .3s ease-in-out;
    width: 30px;
}

.footer a:hover svg {
   opacity : 0.85;
}

.legal {
    font-weight: 500;
}

.legal>* {
    color: var(--color-white);
    margin:  0 var(--space-s);
    transition: opacity .3s ease-in-out;
}

.legal>*:first-child {
    font-weight: 700;
    margin-left: 0;
}

.legal>*:last-child {
    margin-right: 0;
}

.legal>*:hover {
    opacity : 0.85;
}

.icons {
    align-items: center;
    display: flex;
    gap: var(--space-s);
}

@media screen and (width <= 1000px) {
    .footer>div {
        flex-direction: column;
        gap: var(--space-m);
    }

    .legal {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: var(--space-m);
        text-align: center;
    }

    .legal>* {
        margin: 0 !important;
    }
}