.link {
    color: var(--color-primary);
    display: block;
    font-size: 16px;
    letter-spacing: -0.32px;
    line-height: 24px;
    margin: var(--space-m) 0 ;
    transition: all .3s ease-in-out;
    user-select:none;
}

.link:hover {
    opacity: 0.75;
}

.link.white {
    color : var(--color-white);
}