import * as style from './style.module.css';

export const Section = ({ type = 'SECTION', children, ...props }) => {
  return <section {...props} className={`${style.section} ${type === 'BANNER' ? style.banner : ''} ${type === 'SMALL_BANNER' ? style.smallBanner : ''} `}>
    <div>
      {children}
    </div>
  </section>
}

export default Section;
