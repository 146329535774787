import Logo from 'jsx:../assets/logo.svg';
import { useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";

import Title from "../components/Title";

import * as style from './style.module.css';


const JoinBetaWindow = ({ t, onCancel = () => { } }) => {
    const ref = useRef(null);

    useEffect(() => {
        if (ref && ref.current) {
            window.hbspt.forms.create({
                region: "eu1",
                portalId: "139724597",
                formId: "deb687e7-2b06-4e74-a3ca-87518a5b902a",
                target: '#hubspot-form',
                onFormSubmit: () => {
                    onCancel();
                }
            });
        }
    }, [])

    return (
        <>
            <Logo className={style.logo} />
            <Title type="SUBTITLE">{t('Rejoindre la beta')}</Title>
            <div id="hubspot-form" ref={ref} />
        </>
    );
};

export default withTranslation()(JoinBetaWindow);
