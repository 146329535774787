// Always web
export const DEVICE = 'web';
export const REVISION = process.env.COMMIT_HASH || 'local';

// Allowed languages
export const ALLOWED_LANGUAGES = ['fr'];
export const DEFAULT_LANGUAGE = 'fr';

// Local storage keys
export const LS_PREFIX = 'laplace_';
export const LS_LANGUAGE = `${LS_PREFIX}language`;

// Computes build tag
export const BUILD = `${DEVICE.toLowerCase()}-${REVISION.substring(0, 7)}`;

// Env values
export const INTERCOM_ID = process.env.INTERCOM_ID || '';
export const BACKOFFICE_URL = process.env.BACKOFFICE_URL || '';
export const PUBLIC_EMAIL = process.env.PUBLIC_EMAIL || '';
export const GDPR_EMAIL = process.env.GDPR_EMAIL || '';
export const PUBLIC_URL = process.env.PUBLIC_URL || '';