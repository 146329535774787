import * as style from './style.module.css';

const CardsContainer = ({ children, byLine = 1 }) => {
    return <div className={style.cards} style={{
        '--content-size': `calc((100% - (${byLine - 1} * var(--space-m))) / ${byLine})`
    }}>
        {children}
    </div>
}

export default CardsContainer;
