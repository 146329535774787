import Action from "../Action";

import * as styleCss from './style.module.css';

const ExternalLink = ({action = null, children, color = "PRIMARY", style = {}}) => {
    return <Action action={action} className={`${styleCss.link} ${color === 'WHITE' ? styleCss.white : ''}`} style={style}>
        {children}
    </Action>
};

export default ExternalLink;
