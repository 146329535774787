import { withTranslation } from "react-i18next";

import ExternalLink from "../components/ExternalLink";
import Section from "../components/Section";
import Title from "../components/Title";
import { PUBLIC_EMAIL } from "../config";

const LegalNotices = ({ t }) => {
  return (
    <Section id="legal-notices">
      <Title type="SECTION">{t('Propriétaire du site')}</Title>

      <strong>{t('La Place Groupe')}</strong>
      <div>{t('Numéro de SIRET : {{number}}', { number: '97869314100019' })}</div>

      <br />

      <Title type="SECTION">{t('Adresse')}</Title>
      <div>{t('La Place Groupe')}</div>
      <div>10 rue de Penthièvre</div>
      <div>75008 Paris</div>
      <div>France</div>

      <br />

      <Title type="SECTION">{t('Contact')}</Title>
      <div><ExternalLink action={PUBLIC_EMAIL}>{PUBLIC_EMAIL}</ExternalLink></div>

      <br />

      <Title type="SECTION">{t('Hébergement')}</Title>

      <strong>Amazon Web Services Inc</strong>
      <div>PO Box 81226</div>
      <div>Seattle, WA 981808-1226</div>
      <div>USA</div>

      <br />

      <strong>Cloudflare Inc.</strong>
      <div>101 Townsend St, San Francisco</div>
      <div>San Francisco, CA 94107</div>
      <div>USA</div>
    </Section>
  );
}

export default withTranslation()(LegalNotices);