import Action from '../Action';

import * as styleCss from './style.module.css';

const Card = ({ children, className = '', action = '', style = {} }) => {
    if (action) {
        return <Action className={`${styleCss.card} ${className}`} action={action} style={style}>
            {children}
        </Action>;
    }

    return <div className={`${styleCss.card} ${className}`} style={style}>
        {children}
    </div>;
}

export default Card;
