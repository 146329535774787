import Logo from 'jsx:../../assets/logo_white.svg';
import { withTranslation } from 'react-i18next';

import Action from '../Action';
import Button from '../Button';

import * as style from './style.module.css';

const Navbar = ({ actionLabel, action = null, t }) => {
    const items = [
        { label: t('Accueil'), action: '/#' },
        { label: t('Avantages'), action: '/#features' },
        { label: t('L\'équipe'), action: '/#team' },
    ];

    return (<nav className={style.nav}>
        <div>
            <Action action="/"><Logo className={style.logo} /></Action>
            <ul>
                {items.map(item => <li key={item.label}>
                    <Action action={item.action}>{item.label}</Action>
                </li>)}
            </ul>
            <Button action={action} theme="PRIMARY" label={actionLabel} />
        </div>
    </nav>);
};

export default withTranslation()(Navbar);
