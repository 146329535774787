.cards {
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-m);
    justify-content: center;

    --content-size: calc((100% - (3 * var(--space-m))) / 4);
}

.cards>* {
    margin: 0;
    width: var(--content-size) !important;
}


@media screen and (width <=900px) {
    .cards {
        display: flex;
        flex-direction: column;
    }

    .cards>* {
        width: 100% !important;
    }
}