import { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

import { Container } from './components/Container';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import { BACKOFFICE_URL, PUBLIC_EMAIL } from './config';
import Homepage from './views/Homepage';
import LegalNotices from './views/LegalNotices';
import Privacy from './views/Privacy';


const ROUTES = {
    Home: {
        path: () => '/',
        Component: Homepage,
    },
    Privacy: {
        path: () => `/privacy.html`,
        Component: Privacy,
    },
    LegalNotices: {
        path: () => `/legal-notices.html`,
        Component: LegalNotices,
    },
};

const RoutesList = Object.keys(ROUTES).map(route => ({ id: route, ...ROUTES[route] }));

export default withTranslation()(function RouteDispatcher({ t }) {
    const contactAction = PUBLIC_EMAIL;
    const linkedInAction = 'https://www.linkedin.com/company/la-place-io/';
    const githubAction = 'https://github.com/la-place-organisation';
    const facebookAction = 'https://www.facebook.com/profile.php?id=61550923419976';

    return (<>
        <Container>
            <Routes>
                {RoutesList.map(({ id, path, url = path(), Component }) => (
                    <Route exact key={url} path={url} element={<Fragment key={url}>
                        <Navbar actionLabel={t('Se connecter')} action={`${BACKOFFICE_URL}sign-in`} />
                        <Component key={url} routeId={id} />
                        <Footer
                            contactAction={contactAction}
                            linkedInAction={linkedInAction}
                            githubAction={githubAction}
                            facebookAction={facebookAction}
                        />
                    </Fragment>} />
                ))}
                <Route path="*" element={<Homepage />} />
            </Routes>
        </Container>
    </>);
});

