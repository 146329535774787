.bgd {
  background: rgb(0 0 0 / 0%);
  height: 100vh;
  left: 0;
  opacity: 1;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: background-color 0.3s ease-in-out;
  user-select: none;
  width: 100%;
  z-index: 999999;
}

.window {
  background: var(--color-white);
  border-radius: var(--radius-l);
  box-shadow: 0 0 10px 0 rgb(114 114 255 / 5%);
  left: 50%;
  max-height: calc(100vh - (2 * var(--space-l)));
  max-width: 700px;
  min-height: 5rem;
  opacity: 0;
  overflow: auto;
  padding: var(--space-l);
  position: fixed;
  top: 50vh;
  transform: translate(-50%, -50%) scale(0.85);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  width: calc(100% - (2 * var(--space-l)));
}

.content {
  box-sizing: border-box;
}

.opened {
  background: rgb(0 13 22 / 90%);
  pointer-events: all;
}

.opened .window {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}

.close {
  color: var(--color-primary);
  cursor: pointer;
  display: inline-block;
  height: 1.25rem;
  opacity: 1;
  position: absolute;
  right: var(--space-m);
  top: var(--space-m);
  transition: opacity 0.3s ease-in-out;
  user-select: none;
  width: 1.25rem;
  z-index: 5;
}

.close:hover {
  opacity: 0.7;
}

.close svg {
  height: 100%;
  width: 100%;
}

@media screen and (width <= 450px) {
  .window {
    border-radius: 0;
    box-sizing: border-box;
    height: 100%;
    top: 5%;
    transform: translate(-50%, 100%);
    width: 100%;
  }

  .opened .window {
    bottom: 0;
    opacity: 1;
    transform: translate(-50%, 0%);
  }
}
