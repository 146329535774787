import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Action from '../Action';

import * as style from './style.module.css';

export const Button = ({ label = '', loading = false, active = false, icon = null, disabled = false, className = '', theme = 'PRIMARY', size='MEDIUM', action = null, ...rest }) => {
    const parsedLabel = `${label}`.trim();

    return <Action
        className={`${style.button} ${active ? style.active : ''} ${style[theme.toLowerCase()]} ${loading ? style.loading : ''} ${style[size.toLowerCase()]} ${disabled ? style.disabled : ''} ${parsedLabel ? style.text : ''} ${className}`}
        action={disabled || loading ? null : action}
        {...rest}
    >
        {icon && <FontAwesomeIcon className={style.icon} icon={icon} />}
        <span className={style.text}>{parsedLabel}</span>
    </Action>
};

export default Button;
