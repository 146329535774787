.button {
    align-items: center;
    background: linear-gradient(135deg, rgb(61 104 255 / 80%) 0%, #8295E4 100%);
    border: none;
    border-radius: var(--radius-m);
    box-shadow: -4px -4px 12px 0 rgb(255 255 255 / 20%) inset, 4px 4px 12px 0 rgb(0 0 0 / 25%), 0 4px 4px 0 rgb(0 0 0 / 10%) inset;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-weight: 400;
    justify-content: center;
    transition: opacity .3s ease-in-out;
    user-select: none;
}

.button:hover {
    opacity: 0.85;
}

.small {
    font-size: var(--font-size-xxs);
    height: 32px;
    line-height: var(--font-line-xxs);
    padding: 0 var(--space-s);
}

.medium {
    font-size: var(--font-size-xs);
    height: 40px;
    line-height: var(--font-line-xs);
    padding: 0 var(--space-m);
}

.large {
    font-size: var(--font-size-s);
    height: 54px;
    line-height: var(--font-line-s);
    padding: 0 var(--space-xl);
}






/***************
     COLORS
***************/

/* neutral */
.neutral {
    background-color: var(--color-neutral-300);
}

.neutral.active,
.neutral:hover {
    background-color: var(--color-neutral-400);
}

.neutral.disabled {
    background-color: var(--color-neutral-50);
}

/* primary */
.primary {
    /* background-color: var(--color-primary-300); */
}

.primary.active,
.primary:hover {
    /* background-color: var(--color-primary-400); */
}

.primary.disabled {
   /* background-color: var(--color-primary-50); */
}

/* secondary */
.secondary {
    background-color: var(--color-secondary-300);
}

.secondary.active,
.secondary:hover {
    background-color: var(--color-secondary-400);
}

.secondary.disabled {
    background-color: var(--color-secondary-50);
}

/* success */
.success {
    background-color: var(--color-success-300);
}

.success.active,
.success:hover {
    background-color: var(--color-success-400);
}

.success.disabled {
    background-color: var(--color-success-50);
}

/* danger */
.danger {
    background-color: var(--color-danger-300);
}

.danger.active,
.danger:hover {
    background-color: var(--color-danger-400);
}

.danger.disabled {
    background-color: var(--color-danger-50);
}

/* warning */
.warning {
    background-color: var(--color-warning-300);
}

.warning.active,
.warning:hover {
    background-color: var(--color-warning-400);
}

.warning.disabled {
    background-color: var(--color-warning-50);
}

/* info */
.info {
    background-color: var(--color-info-300);
}

.info.active,
.info:hover {
    background-color: var(--color-info-400);
}

.info.disabled {
    background-color: var(--color-info-50);
}