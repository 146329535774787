import { faBrainCircuit, faHouseHeart, faMicrochipAi, faPartyHorn } from '@fortawesome/pro-duotone-svg-icons';
import { withTranslation } from 'react-i18next';

import Button from '../components/Button';
import ButtonsContainer from '../components/ButtonsContainer';
import CardsContainer from '../components/CardsContainer';
import ExternalLink from '../components/ExternalLink';
import FeatureCard from '../components/FeatureCard';
import Section from "../components/Section";
import TeamCard from '../components/TeamCard';
import Title from "../components/Title";
import { useWindow } from '../contexts/WindowContext';
import JoinBetaWindow from '../windows/JoinBetaWindow';
import StoreBadge from '../components/StoreBadge';
import { getCurrentLanguage } from '../i18n';

import * as style from './style.module.css';

const Homepage = ({ t }) => {
    const { actions: windowActions } = useWindow();

    // CTA
    const bookACallAction = 'https://meetings-eu1.hubspot.com/alexis-dupre';

    const betaAction = () => {
        windowActions.open({
            component: () => (
                <JoinBetaWindow
                    onCancel={() => {
                        windowActions.close();
                    }}
                />
            ),
        });
    };

    return <>
        <Section id="home" type="BANNER">
            <Title type="TITLE">{t('Transformez votre agence avec le futur de l\'immobilier')}</Title>
            <Title type="SUBTITLE">{t('La plateforme tout-en-un, mobile first, qui va vous changer la vie !')}</Title>

            <ButtonsContainer>
                <Button size="LARGE" action={betaAction} label={t('Je deviens bêta testeur')} />
                <br />
                <ExternalLink action={bookACallAction} color="WHITE">{t('Je programme une visioconférence')}</ExternalLink>
            </ButtonsContainer>
        </Section>

        <Section id="features">
            <Title type="SECTION">{t('Nos avantages')}</Title>
            <CardsContainer byLine={2}>
                <FeatureCard style={{ animationDelay: "0s" }} title={t('Plateforme intuitive')} description={t('Simplicité et efficacité définissent notre interface pour les professionnels de l\'immobilier. Oubliez les tracas, naviguez aisément pour atteindre vos objectifs plus rapidement.')} icon={faPartyHorn} />
                <FeatureCard style={{ animationDelay: "0.15s" }} title={t('La puissance de l\'IA')} description={t('Faites briller vos annonces immobilières grâce à notre IA de home staging. Sublimez les photos de vos biens gratuitement, instantanément et sans tracas.')} icon={faMicrochipAi} />
                <FeatureCard style={{ animationDelay: "0.30s" }} title={t('Plateforme intelligente')} description={t('Développée par des experts de l\'immobilier, notre plateforme associe l\'expérience métier à l\'innovation technologique. Elle est conçue pour répondre précisément à vos besoins et optimiser votre efficacité.')} icon={faBrainCircuit} />
                <FeatureCard style={{ animationDelay: "0.45s" }} title={t('Matching sophistiqué')} description={t('Décuplez la puissance de vos matchings ! Trouvez facilement les biens les plus pertinents pour vos acheteurs en fonction de leurs critères de recherche.')} icon={faHouseHeart} />
            </CardsContainer>
        </Section>
        
        <Section id="store">
            <Title type="SECTION">{t('Disponible sur')}</Title>
            <div className={style.badgeContainer}>
                <StoreBadge type="APPLE" language={getCurrentLanguage()} action="https://apps.apple.com/fr/app/la-place/id6471098155"/>
                <StoreBadge type="GOOGLE" language={getCurrentLanguage()} action="https://play.google.com/store/apps/details?id=io.laplace.app"/>
            </div>
        </Section>

        <Section id="team">
            <Title type="SECTION">{t('Notre équipe')}</Title>
            <CardsContainer byLine={4}>
                <TeamCard name="Joël André Ornstein" description={t('Diplômé de l\'ESCP, la City University London, l\'université de Düsseldorf et du MIT, Jöel a toujours officié dans le monde de la finance et de l\'investissement. Il fut l\'un des pionniers du private equity avec la création, en 1989, de Majorn Corp, une joint-venture entre le groupe Carlyle et le groupe Euris-Rallye.')} role="Chairman" id="joel-andre-ornstein" wikipedia="https://en.wikipedia.org/wiki/Jo%C3%ABl_Andr%C3%A9_Ornstein" linkedin="https://www.linkedin.com/in/jo%C3%ABl-andr%C3%A9-ornstein-731370149/" />
                <TeamCard name="Alexis Dupré" description={t('Alexis dispose d\'une solide expérience dans l\'industrie immobilière, notamment au sein de maisons prestigieuses. Son goût pour l\'innovation et l\'entrepreneuriat l\'ont mené à piloter différents projets avant de co-fonder La Place dont l\'objectif est d\'aider les professionnels de l\'immobilier dans la création d\'une expérience client exceptionnelle.')} role="CEO" id="alexis-dupre" linkedin="https://www.linkedin.com/in/alexis-dupr%C3%A9-4849b11aa/" email="a.dupre@la-place.io" />
                <TeamCard name="Maxime Baconnais" description={t('Passionné de tech depuis son plus jeune âge et diplômé d\'Epitech, Maxime adore solutionner des problématiques complexes avec des solutions innovantes. Il a travaillé chez Teester/Skeepers pendant plus de 4 ans où il a gravit les échelons pour devenir Tech Lead aux côtés du CTO.')} role="CTO" id="maxime-baconnais" linkedin="https://www.linkedin.com/in/maxime-baconnais/" github="https://github.com/Maxou44" email="m.baconnais@la-place.io" />
                <TeamCard name="Blanche Frankel" description={t('Quadrilingue, Blanche a travaillé dans un environnement international depuis plus de 25 ans, que ce soit en France comme aux Etats-Unis, occupant des postes à responsabilités dans les médias, la communication et l\'immobilier résidentiel. Co-fondatrice de La Place, Blanche apporte à l\'équipe ses compétences linguistiques, sa connaissance de l\'international et la compréhension des différences culturelles propres à chaque pays.')} role="Global Chief Brand Officer" id="blanche-frankel" linkedin="https://www.linkedin.com/in/blanche-frankel-75088447/" email="b.frankel@la-place.io" />
                <TeamCard name="Maxime Pouezevara" description={t(`Diplômé d'Epitech et d'une année à Dublin City University, Maxime possède une solide expérience dans la conception et le développement de projets web. Après avoir occupé le poste de Lead développeur chez Teester/Skeepers, il se lance pleinement dans l'entrepreneuriat en co-fondant La Place. En tant que VP Engineering, il met à profit son expertise pour promouvoir l'innovation et offrir une expérience de qualité au marché de l'immobilier.`)} role="VP Engineering" id="maxime-pouezevara" linkedin="https://www.linkedin.com/in/maximepzv/" github="https://github.com/Maximepzv" email="m.pouezevara@la-place.io" />
                <TeamCard name="Yves Bismuth" description={t('Ingénieur de formation, Yves fit carrière, durant près de 40 ans, au sein d\'IBM, société dans laquelle il gravit tous les échelons, débutant en qualité d\'ingénieur informatique Yves finit à la tête d\'une équipe de plus de 250 personnes et devient expert sur les sujets de management, recrutement et culture d\'entreprise.')} role="Advisor" id="yves-bismuth" linkedin="https://www.linkedin.com/in/yves-bismuth-096a484a/" />
                <TeamCard name="Marc Fourrier" description={t('Après un parcours riche d\'expériences multiples (communication, commercial, événementiel, artistique...), Marc évolue dans l\'immobilier depuis une vingtaine d\'années, de conseiller transaction à la direction du recrutement et de la formation chez Engel & Volkers France. Co-fondateur de La Place, il apporte sa vision "métier" à l\'équipe.')} role="Consultant" id="marc-fourrier" linkedin="https://www.linkedin.com/in/marcfourrier/" email="m.fourrier@la-place.io" />
            </CardsContainer>
        </Section>

        <Section id="beta" type="SMALL_BANNER">
            <Title type="TITLE_SMALL">{t('Profitez d\'avantages exclusifs en devenant bêta testeur')}</Title>
            <Title type="SUBTITLE_SMALL">{t('Participez à la révolution de l\'intermédiation de biens immobiliers et l\'amélioration de notre plateforme via notre programme de bêta testing.')}</Title>

            <ButtonsContainer>
                <Button size="LARGE" action={betaAction} label={t('Je deviens bêta testeur')} />
                <br />
                <ExternalLink action={bookACallAction}>{t('Je programme une visioconférence')}</ExternalLink>
            </ButtonsContainer>
        </Section>
    </>;
}

export default withTranslation()(Homepage);
