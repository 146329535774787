import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next"

import { ALLOWED_LANGUAGES, BUILD, DEFAULT_LANGUAGE, LS_LANGUAGE, PUBLIC_URL } from "./config";

i18n
  .use(backend)
  .use(detector)
  .use(initReactI18next)
  .init({
    compatibilityJSON: 'v4',

    fallbackLng: DEFAULT_LANGUAGE,
    keySeparator: false,
    nsSeparator: false,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `${PUBLIC_URL}i18n/{{lng}}.json?${BUILD}`,
    },
    react: {
      useSuspense: false,
    },
    detection: {
      lookupLocalStorage: LS_LANGUAGE,
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,
      caches: ["localStorage"],
      checkWhitelist: true,
      checkForSimilarInWhitelist: false,
    },
    supportedLngs: ALLOWED_LANGUAGES,
  });

export default i18n;

export const getCurrentLanguage = () => localStorage.getItem(LS_LANGUAGE) || DEFAULT_LANGUAGE || 'en';

export const setCurrentLanguage = (lng = 'en') => i18n.changeLanguage(lng);