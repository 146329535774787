:root {
    /* 400 are base values */

    /* Generated using https://mdigi.tools/color-shades/ */

    /* Neutral */
    --color-neutral-50: #FFF;
    --color-neutral-100: #e7e7e7;
    --color-neutral-200: #cfcece;
    --color-neutral-300: #b8b6b6;
    --color-neutral-400: #a09e9e;
    --color-neutral-500: #888585;
    --color-neutral-600: #706d6d;
    --color-neutral-700: #585656;
    --color-neutral-800: #3f3e3e;
    --color-neutral-900: #1f1f1f;
    --color-neutral: var(--color-neutral-400);

    /* Primary */
    --color-primary-50: #eaedfa;
    --color-primary-100: #c0caf1;
    --color-primary-200: #96a6e8;
    --color-primary-300: #6c83df;
    --color-primary-400: #425fd6;
    --color-primary-500: #2945bd;
    --color-primary-600: #203693;
    --color-primary-700: #172769;
    --color-primary-800: #0e173f;
    --color-primary-900: #050815;
    --color-primary: var(--color-primary-400);

    /* Secondary */
    --color-secondary-50: #fde9e7;
    --color-secondary-100: #fabdb8;
    --color-secondary-200: #f79088;
    --color-secondary-300: #f36458;
    --color-secondary-400: #f03829;
    --color-secondary-500: #d61e0f;
    --color-secondary-600: #a7170c;
    --color-secondary-700: #771108;
    --color-secondary-800: #470a05;
    --color-secondary-900: #180302;
    --color-secondary: var(--color-secondary-400);

    /* Success */
    --color-success-50: #e8fcf0;
    --color-success-100: #bbf6d3;
    --color-success-200: #8ef1b5;
    --color-success-300: #60eb98;
    --color-success-400: #33e57a;
    --color-success-500: #1acc61;
    --color-success-600: #149f4b;
    --color-success-700: #0e7136;
    --color-success-800: #094420;
    --color-success-900: #03170b;
    --color-success: var(--color-success-400);

     /* Danger */
     --color-danger-50: #fbeae9;
     --color-danger-100: #f3c0be;
     --color-danger-200: #eb9693;
     --color-danger-300: #e46d68;
     --color-danger-400: #dc433d;
     --color-danger-500: #c22923;
     --color-danger-600: #97201b;
     --color-danger-700: #6c1714;
     --color-danger-800: #410e0c;
     --color-danger-900: #160504;
     --color-danger: var(--color-danger-400);

    /* Warning */
    --color-warning-50: #fff2e5;
    --color-warning-100: #ffd7b3;
    --color-warning-200: #ffbd80;
    --color-warning-300: #ffa24d;
    --color-warning-400: #ff881a;
    --color-warning-500: #e66f00;
    --color-warning-600: #b35600;
    --color-warning-700: #803d00;
    --color-warning-800: #4d2500;
    --color-warning-900: #1a0c00;
    --color-warning: var(--color-warning-400);

    /* Info */
    --color-info-50: #e5ebff;
    --color-info-100: #b3c3ff;
    --color-info-200: #809cff;
    --color-info-300: #4d74ff;
    --color-info-400: #1a4cff;
    --color-info-500: #0033e6;
    --color-info-600: #0028b3;
    --color-info-700: #001c80;
    --color-info-800: #00114d;
    --color-info-900: #00061a;
    --color-info: var(--color-info-400);

    /* Base */
    --color-white: var(--color-neutral-50);
    --color-black: var(--color-neutral-900);

    /* Shadow */
    --shadow-50: drop-shadow(0px 4px 4px rgb(0 0 0 / 25%));
    --shadow-100: drop-shadow(0px 4px 4px rgb(0 0 0 / 25%));
    --shadow-200: drop-shadow(0px 4px 4px rgb(0 0 0 / 25%));
    --shadow-300: drop-shadow(0px 4px 4px rgb(0 0 0 / 25%));
    --shadow-400: drop-shadow(0px 4px 4px rgb(0 0 0 / 25%));
    --shadow-500: drop-shadow(0px 4px 4px rgb(0 0 0 / 25%));
    --shadow-600: drop-shadow(0px 4px 4px rgb(0 0 0 / 25%));
    --shadow-700: drop-shadow(0px 4px 4px rgb(0 0 0 / 25%));
    --shadow-800: drop-shadow(0px 4px 4px rgb(0 0 0 / 25%));
    --shadow-900: drop-shadow(0px 4px 4px rgb(0 0 0 / 25%));
    --shadow: var(--shadow-400);

    /* Font */
    --font-family: outfit, sans-serif;

    /* Font sizes */
    --font-size-xxs: 12px;
    --font-size-xs: 14px;
    --font-size-s: 16px;
    --font-size-m: 20px;
    --font-size-l: 24px;
    --font-size-xl: 32px;
    --font-size-xxl: 40px;

    /* Font line heights */
    --font-line-xxs: 16px;
    --font-line-xs: 24px;
    --font-line-s: 24px;
    --font-line-m: 32px;
    --font-line-l: 32px;
    --font-line-xl: 48px;
    --font-line-xxl: 48px;

    /* Spacing */
    --space-xxs: 4px;
    --space-xs: 8px;
    --space-s: 12px;
    --space-m: 16px;
    --space-l: 24px;
    --space-xl: 32px;
    --space-xxl: 48px;

    /* Radius */
    --radius-xs: 2px;
    --radius-s: 4px;
    --radius-m: 8px;
    --radius-l: 16px;
    --radius-xl: 24px;
}

* {
    font-family: Outfit, sans-serif;
    scroll-behavior: smooth;
    -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
}

body {
    /* Body style */
    margin: 0;
    padding: 0;
}

.container {
    margin: auto;
    position: relative;
}

*::-webkit-scrollbar {
    width: 10px;
}

*::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: var(--color-neutral-400);
    border: 2px solid transparent;
    border-radius: 8px;
    cursor: pointer;
    min-height: 50px;
}

*::-webkit-scrollbar-track {
    background-color: rgb(0 0 0 / 0%);
}

::selection {
    background: #425fd64d;
}