import { faGithub, faLinkedin, faWikipediaW } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Action from "../Action";
import Card from "../Card";
import imgAlexisDupre from './assets/alexis-dupre.png?as=jpg&width=150';
import imgJoelAndreOrnstein from './assets/joel-andre-ornstein.png?as=jpg&width=150';
import imgMarcFourrier from './assets/marc-fourrier.png?as=jpg&width=150';
import imgMaximeBaconnais from './assets/maxime-baconnais.png?as=jpg&width=150';
import imgMaximePouezevara from './assets/maxime-pouezevara.png?as=jpg&width=150';
import imgYvesBismuth from './assets/yves-bismuth.png?as=jpg&width=150';
import imgBlancheFrankel from './assets/blanche-frankel.png?as=jpg&width=150';
import imgUgoMartin from './assets/ugo-martin.png?as=jpg&width=150';
import imgFosterProvost from './assets/foster-provost.png?as=jpg&width=150';

import * as style from './style.module.css';

const imgs = {
    'marc-fourrier': imgMarcFourrier,
    'maxime-baconnais': imgMaximeBaconnais,
    'maxime-pouezevara': imgMaximePouezevara,
    'yves-bismuth': imgYvesBismuth,
    'alexis-dupre': imgAlexisDupre,
    'joel-andre-ornstein': imgJoelAndreOrnstein,
    'blanche-frankel': imgBlancheFrankel,
    'ugo-martin': imgUgoMartin,
    'foster-provost': imgFosterProvost,
}

const TeamCard = ({ id = '', name = '', description = '', role = '', linkedin = '', github = '', email = '', wikipedia = '', ...rest }) => {
    return <Card className={style.user} {...rest}>
        <img src={imgs[id]} />
        <h1>{name}</h1>
        <h2>{role}</h2>
        <div className={style.icons}>
            {linkedin && <Action action={linkedin}><FontAwesomeIcon icon={faLinkedin} /></Action>}
            {github && <Action action={github}><FontAwesomeIcon icon={faGithub} /></Action>}
            {wikipedia && <Action action={wikipedia}><FontAwesomeIcon icon={faWikipediaW} /></Action>}
            {email && <Action action={email}><FontAwesomeIcon icon={faEnvelope} /></Action>}
        </div>

        {description && <div className={style.description}>
            <div>{description}</div>

            <div className={style.icons}>
                {linkedin && <Action action={linkedin}><FontAwesomeIcon icon={faLinkedin} /></Action>}
                {github && <Action action={github}><FontAwesomeIcon icon={faGithub} /></Action>}
                {wikipedia && <Action action={wikipedia}><FontAwesomeIcon icon={faWikipediaW} /></Action>}
                {email && <Action action={email}><FontAwesomeIcon icon={faEnvelope} /></Action>}
            </div>
        </div>}
    </Card>
}

export default TeamCard;
