import * as style from './style.module.css';

const Title = ({ type = 'TITLE', children }) => {
    if (type === 'TITLE') {
        return <h1 className={`${style.base} ${style.title}`}>{children}</h1>
    }
    if (type === 'SUBTITLE') {
        return <h2 className={`${style.base} ${style.subtitle}`}>{children}</h2>
    }
    if (type === 'TITLE_SMALL') {
        return <h1 className={`${style.base} ${style.titleSmall}`}>{children}</h1>
    }
    if (type === 'SUBTITLE_SMALL') {
        return <h2 className={`${style.base} ${style.subtitleSmall}`}>{children}</h2>
    }
    if (type === 'SECTION') {
        return <h3 className={`${style.base} ${style.section}`}>{children}</h3>
    }
    return null;
}

export default Title;
