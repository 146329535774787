.badgeContainer {
    gap: 50px;
    margin: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
}

@media screen and (width <=680px) {
    .badgeContainer {
        flex-direction: column;
    }
}