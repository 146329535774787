.user {
    align-items: center;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    padding: var(--space-l) var(--space-m) !important;
}

.user h1 {
    font-size: var(--font-size-m);
    font-weight: 600;
    line-height: var(--font-line-m);
    margin: 0;
    margin-top: var(--space-s);
}

.user h2 {
    font-size: var(--font-size-s);
    font-weight: 400;
    line-height: var(--font-line-s);
    margin: 0;
}

.user img {
    border-radius: 50%;
    color: var(--color-primary);
    height: 100px;
    object-fit: cover;
    width: 100px;
}

.icons {
    display: flex;
    gap: var(--space-s);
    margin-top: var(--space-s);
}

.icons a svg {
    color: var(--color-primary-300);
    height: 20px;
    transition: color .3s ease-in-out;
    width: 20px;
}

.icons a:hover svg {
    color: var(--color-primary-400) !important;
}

.description {
    align-content: space-between;
    align-items: center;
    background: #222831;
    border-radius: var(--radius-l);
    color: var(--color-white);
    display: flex;
    flex-direction: column;
    font-size: 12px;
    inset: 0;
    justify-content: space-between;
    padding: var(--space-m);
    position: absolute;
    transform: translate(0%, 101%);
    transition: transform 0.4s ease-in-out;
}

@media (pointer: coarse) {
    .description {
        margin: var(--space-m) 0;
        margin-bottom:0;
        position: relative;
        transform:none;
    }

    .description .icons {
        display:none;
    }
}

.user:hover .description {
    transform: translate(0, 0);
}

.description .icons a svg {
    color: var(--color-neutral-100);
}

.description .icons a:hover svg {
    color: var(--color-neutral-400) !important;
}