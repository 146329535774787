.card {
    background-color: var(--color-white);
    border-radius: var(--radius-l);
    box-shadow: 0 6px 12px 0 rgb(20 20 43 / 5%);
    box-sizing: border-box;
    color: var(--color-black);
    list-style: none;
    padding: var(--space-m);
    text-decoration: none;
}
