.section {
    position: relative;
}

.section>div {
    margin: auto;
    margin-top: calc(3 * var(--space));
    max-width: 1100px;
    width: calc(100% - (2* var(--space-m)));
}

.banner {
    align-items: center;
    background: #222831;
    color: var(--color-white);
    display: flex;
    min-height: 500px;
    padding: var(--space-xxl) var(--space-m);
}

.smallBanner {
    align-items: center;
    background: transparent;
    color: var(--color-primary);
    display: flex;
    margin: var(--space-xxl) 0;
    min-height: 500px;
    padding: var(--space-m);
}

.banner>div {
    margin-top: auto;
}