import { faFacebook, faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { withTranslation } from "react-i18next";

import Action from "../Action";

import * as style from "./style.module.css";

const Footer = ({ contactAction, linkedInAction, githubAction, facebookAction, t }) => {
    return <footer className={style.footer}>
        <div>
            <div className={style.legal}>
                <Action action="/#">© {new Date().getFullYear()} - {t('La Place')}. {t('Tous droits réservés.')}</Action>
                <Action action={contactAction}>{t('Nous contacter')}</Action>
                <Action action="/legal-notices.html">{t('Mentions légales')}</Action>
                <Action action="/privacy.html">{t('Politique de confidentialité')}</Action>
            </div>
            <div className={style.icons}>
                <Action action={githubAction} title="Github">
                    <FontAwesomeIcon icon={faGithub} />
                </Action>
                <Action action={linkedInAction} title="LinkedIn">
                    <FontAwesomeIcon icon={faLinkedin} />
                </Action>
                <Action action={facebookAction} title="Facebook">
                    <FontAwesomeIcon icon={faFacebook} />
                </Action>
            </div>
        </div>
    </footer>
}

export default withTranslation()(Footer);
