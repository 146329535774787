.nav {
    background: #222831;
}

.nav>div {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: auto;
    max-width: 1100px;
    padding: var(--space-m) 0;
    width: calc(100% - (2* var(--space-m)));
}


.nav ul {
    display: flex;
    gap: var(--space-l);
    list-style: none;
    padding: 0;
}

.nav ul li a,
.nav ul li a:visited {
    color: var(--color-white);
    font-size: var(--font-size-s);
    line-height: var(--font-line-s);
    text-decoration: none;
    transition: color .3s;
}

.nav ul li a:hover {
    color: var(--color-primary);
}

.logo {
    max-height: 48px;
}

@media screen and (width <= 950px) {
    .nav ul {
        display: none;
    }
}
