import { INTERCOM_ID } from "./config";
import { getCurrentLanguage } from "./i18n";

const INTERCOM_API = "https://api-iam.intercom.io";

// Documentation: https://developers.intercom.com/installing-intercom/web/methods/
export const Intercom = {
    init: () => {
        try {
            if (INTERCOM_ID) {
                (function () { var w = window; var ic = w.Intercom; if (typeof ic === "function") { ic('reattach_activator'); ic('update', w.intercomSettings); } else { var d = document; var i = function () { i.c(arguments); }; i.q = []; i.c = function (args) { i.q.push(args); }; w.Intercom = i; var l = function () { var s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://widget.intercom.io/widget/' + INTERCOM_ID; var x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); }; if (document.readyState === 'complete') { l(); } else if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); } } })();
                window.Intercom("boot", {
                    api_base: INTERCOM_API,
                    app_id: INTERCOM_ID,
                    language_override: getCurrentLanguage(),
                });
            }
        } catch (e) { } // eslint-disable-line no-empty
    },
    update: (data) => {
        try {
            if (window.Intercom && INTERCOM_ID) {
                window.Intercom("update", {
                    ...data,
                    api_base: INTERCOM_API,
                    app_id: INTERCOM_ID,
                    language_override: getCurrentLanguage(),
                });
            }
        } catch (e) { } // eslint-disable-line no-empty
    },
    flush: () => {
        try {
            if (window.Intercom && INTERCOM_ID) {
                window.Intercom("shutdown", {
                    api_base: INTERCOM_API,
                    app_id: INTERCOM_ID,
                    language_override: getCurrentLanguage(),
                });
            }
        } catch (e) { } // eslint-disable-line no-empty
    }
};
