import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Card from "../Card";

import * as style from './style.module.css';

const FeatureCard = ({ title = '', icon = null, description = '', ...rest }) => {
    return <Card className={style.feature} {...rest}>
        {icon && <FontAwesomeIcon icon={icon} />}
        <h1>{title}</h1>
        <p>{description}</p>
    </Card>
}

export default FeatureCard;
