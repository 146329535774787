.action {
  cursor: pointer;
  outline: none;
  text-decoration: none;
}

.action,
.action:visited,
.action:hover,
.action:active {
  text-decoration: none;
}

.noaction {
  cursor: default;
}
