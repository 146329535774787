import { withTranslation } from 'react-i18next';

import ExternalLink from '../components/ExternalLink';
import Section from "../components/Section";
import Title from "../components/Title";
import { GDPR_EMAIL } from '../config';

const Privacy = ({ t }) => {
    return <Section id="privacy">

        <Title type="SECTION">{t('Politique de confidentialité')}</Title>

        <p>{t(`Le site web et l'application La Place sont détenus par La Place Groupe, qui est un contrôleur de données de vos données personnelles.`)}</p>

        <p>{t(`Nous avons adopté cette politique de confidentialité, qui détermine la manière dont nous traitons les informations collectées par La Place, qui fournit également les raisons pour lesquelles nous devons collecter certaines données personnelles vous concernant. Par conséquent, vous devez lire cette politique de confidentialité avant d'utiliser le service de La Place.`)}</p>

        <p>{t(`Nous prenons soin de vos données personnelles et nous nous engageons à en garantir la confidentialité et la sécurité.`)}</p>

        <p><strong>{t(`Les informations personnelles que nous collectons :`)}</strong><br />
        {t(`Lorsque vous utilisez les services de La Place, nous recueillons automatiquement certaines informations sur votre appareil, notamment des informations sur votre navigateur web, votre adresse IP, votre fuseau horaire et certains des cookies installés sur votre appareil. En outre, nous collectons les données personnelles que vous nous fournissez (y compris, mais sans s'y limiter, le nom, le prénom, le numéro de téléphone, etc.) lors de l'inscription afin de pouvoir exécuter le contrat.`)}</p>

        <p><strong>{t(`Pourquoi traitons-nous vos données ?`)}</strong><br />
        {t(`Notre priorité absolue est la sécurité des données des clients et, à ce titre, nous ne pouvons traiter que des données minimales sur les utilisateurs, uniquement dans la mesure où cela est absolument nécessaire pour maintenir le service. Les informations collectées automatiquement sont utilisées uniquement pour identifier les cas potentiels d'abus et établir des informations statistiques concernant l'utilisation du service. Ces informations statistiques ne sont pas autrement agrégées de manière à identifier un utilisateur particulier du système.`)}</p>

        <p>{t(`Vous pouvez visiter le site web de présentation sans nous dire qui vous êtes ni révéler d'informations, par lesquelles quelqu'un pourrait vous identifier comme un individu spécifique et identifiable. Toutefois, si vous souhaitez utiliser nos services, ou si vous souhaitez que nous prenions contact avec vous en remplissant un formulaire, vous devez nous fournir des données personnelles, telles que votre e-mail, votre prénom, votre nom ou votre votre numéro de téléphone.`)}</p>

        <p><strong>{t(`Vos droits :`)}</strong><br />
        {t(`Si vous êtes un résident européen, vous disposez des droits suivants liés à vos données personnelles :`)}</p>

        <ul>
            <li>{t(`Le droit d'être informé.`)}</li>
            <li>{t(`Le droit d'accès.`)}</li>
            <li>{t(`Le droit de rectification.`)}</li>
            <li>{t(`Le droit à l'effacement.`)}</li>
            <li>{t(`Le droit de restreindre le traitement.`)}</li>
            <li>{t(`Le droit à la portabilité des données.`)}</li>
            <li>{t(`Le droit d'opposition.`)}</li>
            <li>{t(`Les droits relatifs à la prise de décision automatisée et au profilage.`)}</li>
            <li>{t(`Si vous souhaitez exercer ce droit, veuillez nous contacter via `)}<ExternalLink style={{display: 'inline'}} action={GDPR_EMAIL}>{GDPR_EMAIL}</ExternalLink>.</li>
        </ul>

        <p>{t(`En outre, si vous êtes un résident européen, nous notons que nous traitons vos informations afin d'exécuter les contrats que nous avons avec vous, ou autrement pour poursuivre nos intérêts commerciaux légitimes énumérés ci-dessus. En outre, veuillez noter que vos informations pourraient être transférées en dehors de l'Europe, y compris au Canada et aux États-Unis.`)}</p>

        <p><strong>{t(`Liens vers d'autres sites web :`)}</strong><br />
        {t(`Notre service peut contenir des liens vers d'autres sites web et services qui ne sont pas détenus ou contrôlés par nous. Sachez que nous ne sommes pas responsables de ces autres sites web ou des pratiques de confidentialité des tiers. Nous vous encourageons à être attentif lorsque vous quittez nos services et à lire les déclarations de confidentialité de chaque système susceptible de collecter des informations personnelles.`)}</p>

        <p><strong>{t(`Sécurité de l'information :`)}</strong><br />
        {t(`Nous sécurisons les informations que vous fournissez sur des serveurs informatiques dans un environnement contrôlé et sécurisé, protégé contre tout accès, utilisation ou divulgation non autorisés. Nous conservons des garanties administratives, techniques et physiques raisonnables pour nous protéger contre tout accès, utilisation, modification et divulgation non autorisés des données personnelles sous son contrôle et sa garde. Toutefois, aucune transmission de données sur Internet ou sur un réseau sans fil ne peut être garantie.`)}</p>

        <p><strong>{t(`Divulgation légale :`)}</strong><br />
        {t(`Nous divulguerons toute information que nous collectons, utilisons ou recevons si la loi l'exige ou l'autorise, par exemple pour nous conformer à une citation à comparaître ou à une procédure judiciaire similaire, et lorsque nous pensons de bonne foi que la divulgation est nécessaire pour protéger nos droits, votre sécurité ou celle d'autrui, enquêter sur une fraude ou répondre à une demande du gouvernement.`)}</p>

        <p><strong>{t(`Informations de contact :`)}</strong><br />
        {t(`Si vous souhaitez nous contacter pour comprendre davantage la présente politique ou si vous souhaitez nous contacter concernant toute question relative aux droits individuels et à vos informations personnelles, vous pouvez nous contacter via`)} <ExternalLink style={{display: 'inline'}} action={GDPR_EMAIL}>{GDPR_EMAIL}</ExternalLink>.</p>
    </Section>
}

export default withTranslation()(Privacy);



