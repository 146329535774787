.base {
    font-weight: 500;
    margin: var(--space-xl) auto;
    overflow-wrap: anywhere;
    text-align: center;
}

.base strong {
    color: var(--color-primary);
}

.title {
    font-size: 82px;
    font-weight: 600;
    letter-spacing: -3.84px;
    line-height: 112px;
}

.subtitle {
    font-size: 28px;
    font-weight: 300;
    letter-spacing: -0.64px;
    line-height: 48px;
}

.titleSmall {
    font-size: 76px;
    font-weight: 600;
    letter-spacing: -4px;
    text-align: center;
}

.subtitleSmall {
    color: #575D66;
    font-size: 24px;
    letter-spacing: -0.48px;
    line-height: 32px;
    max-width: 780px;
    text-align: center;
}

.section {
    color: var(--color-primary);
    font-size: var(--font-size-xl);
    line-height: var(--font-line-xl);
    margin: var(--space-l) 0;
    margin-top: var(--space-xxl);
}


@media screen and (width <=1175px) {
    .title {
        font-size: 60px;
        line-height: 64px;
    }

    .subtitle {
        font-size: 20px;
        line-height: 24px;
    }

    .titleSmall {
        font-size: 50px;
        line-height: 54px;
    }

    .subtitleSmall {
        font-size: 20px;
        line-height: 24px;
    }
}

@media screen and (width <=850px) {
    .title {
        font-size: 40px;
        letter-spacing: 0;
        line-height: 44px;
    }

    .subtitle {
        font-size: 20px;
        letter-spacing: 0;
        line-height: 24px;
    }

    .titleSmall {
        font-size: 40px;
        letter-spacing: 0;
        line-height: 44px;
    }

    .subtitleSmall {
        font-size: 20px;
        letter-spacing: 0;
        line-height: 24px;
    }
}