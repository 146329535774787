.feature {
    align-items: center;
    animation-duration: 1s;
    animation-fill-mode: backwards;
    animation-name: animate;
    animation-timing-function: ease;
    display: flex;
    flex-direction: column;
}

.feature h1 {
    font-size: var(--font-size-l);
    font-weight: 500;
    line-height: var(--font-line-l);
    margin: 0;
    margin-bottom: var(--space-s);
    margin-top: var(--space-s);
    text-align: center;
}

.feature p {
    font-size: var(--font-size-s);
    line-height: var(--font-line-s);
    margin: 0;
    text-align: justify;
}

.feature svg {
    color: var(--color-primary);
    height: 30px;
    width: 30px;

}

@keyframes animate {
    0% {
        opacity: 0;
        transform: scale(0.8);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}
